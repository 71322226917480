<!-- Control Geolocalización -->

<template>
  
  <v-dialog class="ctrlgeo" v-model="modal" content-class="modal">
    <!-- Textfield a mostrar en pantalla -->
    <template v-slot:activator="{}">
      <v-text-field
        v-bind="$input"
        v-model="data_value"
        :label="ctrl.label"
        :append-icon="icono"
        :disabled="ctrl.comp.f2 ? false : !edicion"
        :readonly="ctrl.comp.f2 ? true : false">

          <template v-slot:append>
            <v-icon :color="get_colorIcon" @click="onClick_append()">
              {{ icono }}
            </v-icon>
          </template>

      </v-text-field>
    </template>

    <div class="conflex" style="justify-content:center">
      <!-- Controles a mostrar en la ventana modal -->
      <div class="columna" style="width:600px">
        <v-sheet style="padding:15px;" >
          <div class="conflex" style="justify-content:center">
            <!-- Título -->
            <v-subheader class="title">
              GEOLOCALIZACIÓN
            </v-subheader>
          </div>
          
          <!-- Botonera -->
          <div v-if="ctrl.value">
            <div class="conflex" style="justify-content:center">
              <v-subheader
                v-if="ctrl.value.substr(0, 5) === 'error'"
                class="justify-center subtitle-1">
                  {{ get_textError() }}
              </v-subheader> 
            </div>

            <div class="conflex" style="justify-content:center">
              <v-btn
                v-if="ctrl.value.substr(0, 5) !== 'error'"
                v-bind="$cfg.btn.busca_with_text"
                @click="ver_gl(ctrl.value)">
                  Ver Posición
                <v-icon right dark>mdi-map-check-outline</v-icon>
              </v-btn>

              <v-btn 
                v-if="edicion && app==2" 
                v-bind="$cfg.btn.busca_with_text"
                @click="get_gl()">
                  Nueva Posición
                <v-icon right dark>mdi-map-marker-plus</v-icon>
              </v-btn>

              <v-btn
                v-bind="$cfg.btn.busca_with_text"
                @click="modal=false">
                  Cancelar
                <v-icon right dark>mdi-cancel</v-icon>
              </v-btn>
            </div>
          </div>
        </v-sheet>
      </div>
    </div>
  </v-dialog>
</template>



<script>
  import { mixinCtrls } from "@/mixins/mixinCtrls.js";

  export default {
    mixins: [mixinCtrls],
    props: {
      schema: { type: [Object, Array], required: true },
      name_ctrl: { type: String, required: true },
      edicion: { type: Boolean, default: false },
      icono: { type: String, default: "mdi-map-marker" }
    },

    data() {
      return {
        // variables de configuración
        Entorno: this.$cfg.ctrls.geolocalizacion.modal,
        BEntorno: this.$cfg.base.F.filtro.btn_wtext,

        // variables del componente
        ctrl: this.schema.ctrls[this.name_ctrl],
        ctrl_f2: this.schema.ctrls[this.name_ctrl].comp.f2
          ? this.schema.ctrls[this.schema.ctrls[this.name_ctrl].comp.f2]
          : "",
        modal: false,
        icon_colorAdvertencia: "orange",
        icon_colorSinpos: "green",
        icon_colorConpos: "red"
      };
    },

    computed: {
      // devuelvo el color a mostrar en el icono, dependiendo
      // si tiene o no coordenadas o si se han grabado erróneamente
      get_colorIcon() {
        if (!this.ctrl_f2) return "";

        // obtengo coordenadas
        let coord = this.ctrl.value;

        // si no hay coordenadas, muestro color 'icon_colorSinpos'
        if (!coord) return this.icon_colorSinpos;

        // si las coordenadas son erróneas, muestro color 'icon_colorAdvertencia'
        if (
          coord === "*" ||
          coord === "**" ||
          coord === "***" ||
          coord.substring(0, 5) == "error"
        )
          return this.icon_colorAdvertencia;

        // si las coordenadas son correctas, muestro color 'icon_colorSinpos'
        return this.icon_colorConpos;
      },

      // value schema principal
      data_value: {
        get() {
          // si NO tiene campo secundario, devuelvo el value del principal
          if (!this.ctrl_f2) return this.ctrl.value;

          // si tiene formato, devuelvo el dato formateado, si no, devuelvo value del secundario
          if (!this.ctrl_f2.comp.format) return this.ctrl_f2.value;
          return this.sql2visual(this.ctrl_f2.comp, this.ctrl_f2.value);
        },

        set(value) {
          // si NO tiene campo secundario, actualizo value schema con el recibido
          if (this.ctrl_f2) return;
          this.ctrl.value = value;
        }
      }
    },

    methods: {
      // gestor de eventos
      event_capture(accion) {
        if (accion === "cerrar_form") this.modal = false;
      },

      // obtengo la geolocalización del navegador
      get_gl() {
        var self = this;

        // nuevo objeto fecha
        const d = new Date();

        // muestro loader
        //this.$root.$loading.show();

        // obtengo la posición del navegador
        navigator.geolocation.getCurrentPosition(onSuccess, onFail, {
          timeout: 10000,
          enableHighAccuracy: false
        });

        // si la geolocalización ha sido satisfactoria, entro y guardo las coordenadas
        function onSuccess(pos) {
          self.ctrl.value = pos.coords.latitude + "," + pos.coords.longitude;

          // si la latitud es errónea, guardo el error 0
          if (
            pos.coords.latitude == "" ||
            pos.coords.latitude == null ||
            pos.coords.latitude == undefined ||
            pos.coords.latitude == 0
          ) {
            self.ctrl.value = "error,0";
          }

          // obtengo y guardo la fecha actual
          let sdate = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
            2,
            "0"
          )}-${String(d.getDate()).padStart(2, "0")}`;
          let stime = `${String(d.getHours()).padStart(2, "0")}:${String(
            d.getMinutes()
          ).padStart(2, "0")}:${String(d.getSeconds()).padStart(2, "0")}`;
          self.ctrl_f2.value = `${sdate} ${stime}`;

          // cierro ventana modal
          self.modal = false;

          // oculto loader
          //self.$root.$loading.hide();

          // muestro mensaje de confirmación
          self.$root.$alert.open("Geolocalización correcta", "success", 2000);
        }

        // si la geolocalización ha sido erronea, guardo el error generado
        function onFail(error) {
          self.ctrl.value = "error," + error.code;

          // obtengo la fecha actual
          self.ctrl_f2.value = `${d.getDate()}-${d.getMonth() +
            1}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;

          // cierro ventana modal
          self.modal = false;

          // oculto loader
          //self.$root.$loading.hide();

          // muestro mensaje de error
          self.$root.$alert.open(
            "Error al obtener la Geolocalización",
            "error",
            2000
          );
        }
      },

      // muestro las coordenadas recibias, en Google Maps
      ver_gl(coord) {
        if (!coord) return;
        window.open("https://maps.google.es/?q=" + coord + "&t='h'", "_blank");
      },

      // acción al pulsar en el botón de geolocalización
      onClick_append() {
        // NO tiene control secundario. Muestro directamente la posición y salgo
        if (!this.ctrl_f2) return this.ver_gl(this.ctrl.value);

        // si hay valor de geolocación, abro ventana
        if (!this.ctrl.value) return;
        this.modal = true;
      },

      // devuelvo el mensaje de error a mostrar según el valor de la geolocalización
      get_textError() {
        switch (this.ctrl.value) {
          case "error,0":
            return "Error de geolocalizacion indeterminado";

          case "error,1":
            return "Compartir ubicación ha sido rechazada";

          default:
            return "Error por configurar. " + this.ctrl.value;
        }
      }
    }
  };
</script>

<style>
.titulo {
  font-weight: bold;
  font-size: 22px;
}

.textarea {
  padding: 20px;
}
</style>
